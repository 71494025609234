import { useStaticQuery, graphql } from 'gatsby'
import _ from 'lodash'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { findImages } from '~components/homepage/utils'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { mediaQueries } from '~components/homepage/styled/constants'
import { ImagesQueryData } from '~src/types/graphql-types'

const Wrapper = styled.div`
  & {
    margin-top: 60px;
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      margin-top: 70px;
    }
  }
`

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;

  & > div {
    flex: 1;
  }

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      width: 80%;
      margin: 0 auto;
      margin-top: 20px;
      flex-direction: column;
    }
  }
`

export const WorkshopGallery = () => {
  const query = useStaticQuery<ImagesQueryData>(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxHeight: 1000) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  const images: object[] = findImages(
    query,
    _.times(8, (idx: number) => `workshop-${idx + 1}`)
  )

  const renderImages = (img, height: string) => {
    const mappedImages = img.map(
      ({ node }): ReactElement => (
        <Zoom key={node.name}>
          <Img
            fluid={node.childImageSharp.fluid}
            imgStyle={{ width: '100%', height, backgroundPosition: 'center' }}
            alt=""
          />
        </Zoom>
      )
    )

    return <Grid>{mappedImages}</Grid>
  }

  return (
    <Wrapper>
      {renderImages(_.slice(images, 0, 3), '216px')}
      {renderImages(_.slice(images, 3, 5), '355px')}
      {renderImages(_.slice(images, 5, 7), '355px')}
      {renderImages(_.slice(images, 7, 8), '456px')}
    </Wrapper>
  )
}
