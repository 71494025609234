import React, { ReactElement, useState, useContext } from 'react'

import { Trans } from 'react-i18next'
import { Select, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import LanguageContext from '~components/homepage/context/language-context'
import { SVGImage } from '~components/homepage/svg/SVGImage'

import { palette } from '~components/homepage/styled/constants'
import { DarkButton } from '~components/homepage/styled/shared'
import { Workshop } from '~src/models/workshop'
import _ from 'lodash'
import { langIndex } from '~components/homepage/utils'
import { sendWorkshopBooking } from '~src/api/email-api'
import { purchase, shippingInfo } from '~src/analytics'
import { WorkshopOrderData } from '../types/index'
import {
  FieldRow,
  StyledModal,
  FieldLabel,
  FieldItem,
  StyledAnchorLink,
  Link,
  SelectWrapper,
} from '../../homepage/styled/order-modal-components'
import { KlosySVGId } from '../../homepage/svg/types/svg'

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

const { Option } = Select

interface OrderModalProps {
  active: boolean
  onCloseModal: () => void
  workshops: Workshop[]
}

const HeaderWrapper = styled.div`
  margin-bottom: 55px;
`

const Checkbox = styled.div<{ error?: boolean }>`
  cursor: pointer;
  flex: 1;
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 1fr;

  .check {
    margin-right: 10px;
    z-index: 10;
    opacity: 0;
  }

  color: ${({ error }) => (error ? palette.error : 'inherit')};

  svg.visible {
    opacity: 1;
    transition: 0.3;
  }

  .empty-check {
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid ${palette.inputBorder};
    z-index: 9;
    background: white;
  }
`

const OrderModal = ({
  active,
  onCloseModal,
  workshops,
}: OrderModalProps): ReactElement => {
  const { t, lang } = useContext(LanguageContext)

  const [sendOrderPending, setSendOrderPending] = useState<boolean>(false)
  // eslint-disable-next-line
  const [orderSentMessage, setOrderSentMessage] = useState<null | string>(null)

  const [formData, setFormData] = useState<WorkshopOrderData>({
    name: '',
    nameMember: '',
    email: '',
    phone: '',
    comment: '',
    giftCard: false,
    privacyPolicy: false,
    bookings: [],
    rodo: false,
  })

  const lngIndex = langIndex(lang)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formErrors, setFormErrors] = useState({
    name: false,
    nameMember: false,
    email: false,
    phone: false,
    bookings: false,
    privacyPolicy: false,
    rodo: false,
  })

  /**
   * Validate and send data to klosy email.
   */
  const onSendOrder = async (): Promise<void> => {
    const validateFields = {
      name: formData.name === '',
      nameMember: formData.nameMember === '',
      email: !/\S+@\S+\.\S+/.test(formData.email),
      phone: formData.phone === '',
      bookings: !formData.bookings.length,
      privacyPolicy: !formData.privacyPolicy,
      rodo: !formData.rodo,
    }
    setFormErrors(validateFields)

    if (
      validateFields.name ||
      validateFields.nameMember ||
      validateFields.email ||
      validateFields.phone ||
      validateFields.bookings ||
      validateFields.privacyPolicy ||
      validateFields.rodo
    )
      return

    setSendOrderPending(true)

    const resp = await sendWorkshopBooking({
      bookings: formData.bookings
        .map(booking => `${booking.workshop.title[0]}, ${booking.date}`)
        .join(' '),
      name: formData.name,
      nameMember: formData.nameMember,
      phone: formData.phone,
      email: formData.email,
      giftCard: formData.giftCard ? 'tak' : 'nie',
      comment: formData.comment,
    })

    const sumPrice = _.sum(
      formData.bookings.map(({ workshop }) => +workshop.price[lngIndex])
    ).toFixed(2)

    shippingInfo('workshop', workshops, 'store')
    purchase(
      'workshop',
      formData.bookings.map(({ workshop }) => workshop),
      lngIndex ? 'EUR' : 'PLN',
      `${sumPrice}`,
      'klosy.pl/workshop'
    )

    setSendOrderPending(false)
    setOrderSentMessage(
      resp.status === 200
        ? t('store.order-sent.title')
        : t('order-modal.orderSentMessageFail')
    )
  }

  const modalProps = {
    visible: active,
    footer: null,
    maskClosable: false,
    title: null,
    onCancel: (): void => onCloseModal(),
    closeIcon: <SVGImage svgProps={[KlosySVGId.cross, 'close', 12, 12]} />,
    width: '900px',
    centered: true,
  }

  const generateField = (
    name: string,
    textarea = false,
    placeholder = undefined
  ): React.ReactElement => (
    <>
      <SVGImage
        svgProps={[KlosySVGId.errorSign, 'error-sign', 17, 17]}
        className={formErrors[name] ? 'error-sign active' : 'error-sign'}
      />

      {textarea ? (
        <textarea
          placeholder={
            placeholder ? t(placeholder) : t(`store.order-modal.${name}`)
          }
          value={formData[name]}
          onChange={(e: any): void =>
            setFormData({ ...formData, [name]: e.target.value })
          }
        />
      ) : (
        <input
          id={name}
          type="text"
          placeholder={
            placeholder ? t(placeholder) : t(`store.order-modal.${name}`)
          }
          value={formData[name]}
          onChange={(e: any): void =>
            setFormData({ ...formData, [name]: e.target.value })
          }
        />
      )}
    </>
  )

  return ((): ReactElement => {
    const sentMessage = (
      <>
        <h3> {orderSentMessage} </h3>
        <div className="sub-title-store">{t('store.order-sent.content1')}</div>
        <br />
        <div className="sub-title-store">{t('store.order-sent.content2')}</div>
        <div className="sub-title-store bold">
          {t('store.order-sent.phone')}
        </div>
        <br />
        <div className="sub-title-store">
          <i>{t('store.order-sent.underline')}</i>
        </div>
      </>
    )

    const form = (
      <>
        <HeaderWrapper>
          <div className="header">{t('workshopPage.orderModal.title')}</div>
          <div className="title title-thin">
            {t('workshopPage.orderModal.subtitle')}
          </div>
          <div
            style={{ marginTop: 10 }}
            className="title title-light title-thin"
          >
            {t('workshopPage.orderModal.subtitle2')}
          </div>
        </HeaderWrapper>

        <FieldRow>
          <FieldLabel>
            <div className="nowrap">
              <div>{t('workshopPage.orderModal.name')}</div>
            </div>
          </FieldLabel>
          <FieldItem>
            {generateField(
              'name',
              false,
              'workshopPage.orderModal.namePlaceholder'
            )}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('workshopPage.orderModal.email')}</FieldLabel>
          <FieldItem>{generateField('email')}</FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('workshopPage.orderModal.phone')}</FieldLabel>
          <FieldItem>{generateField('phone')}</FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>
            <div className="nowrap">
              <div>{t('workshopPage.orderModal.nameMember')}</div>
            </div>
          </FieldLabel>
          <FieldItem>
            {generateField(
              'nameMember',
              false,
              'workshopPage.orderModal.namePlaceholder'
            )}
          </FieldItem>
        </FieldRow>

        {workshops.map(w => (
          <FieldRow key={w.id}>
            <FieldLabel>
              {w.title[lngIndex]} <br /> ({w.time[lngIndex]}) /{' '}
              {w.price[lngIndex]} {t('currency')}
            </FieldLabel>
            <SelectWrapper
              className="generic-dropdown"
              style={{ textAlign: 'left', maxWidth: '370px' }}
            >
              <Select
                getPopupContainer={(trigger): any => trigger.parentNode}
                showArrow={false}
                placeholder={t('workshopPage.orderModal.datePlaceholder')}
                value={
                  formData.bookings.find(({ workshop }) => workshop.id === w.id)
                    ?.date
                }
                onChange={(value: string): void => {
                  setFormData(
                    (v): WorkshopOrderData => {
                      const filtered = v.bookings.filter(
                        ({ workshop }) => workshop.id !== w.id
                      )
                      return {
                        ...v,
                        bookings: [
                          ...filtered,
                          {
                            workshop: w,
                            date: value,
                          },
                        ],
                      }
                    }
                  )
                }}
              >
                {w.date.map(date => (
                  <Option key={date} value={date}>
                    <span className="material-name">{date}</span>
                  </Option>
                ))}
              </Select>
              <SVGImage
                svgProps={[KlosySVGId.arrowDown, 'dropdown-arrow', 19, 11]}
              />
            </SelectWrapper>
          </FieldRow>
        ))}

        <FieldRow>
          <FieldLabel top>{t('workshopPage.orderModal.comment')}</FieldLabel>
          <FieldItem>
            {generateField(
              'comment',
              true,
              'workshopPage.orderModal.commentPlaceholder'
            )}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel></FieldLabel>
          <div>
            <div className="gift-card-title">
              {t('workshopPage.orderModal.giftCard')}
            </div>
            <FieldItem
              onClick={(): void =>
                setFormData(
                  (v): WorkshopOrderData => ({
                    ...v,
                    giftCard: !v.giftCard,
                  })
                )
              }
            >
              <Checkbox>
                <SVGImage
                  className={formData.giftCard ? 'visible check' : 'check'}
                  svgProps={[KlosySVGId.checked, 'checked', 15, 15]}
                />
                <div className="empty-check"></div>{' '}
                <div>{t('workshopPage.orderModal.giftCardPlaceholder')}</div>
              </Checkbox>
            </FieldItem>
          </div>
        </FieldRow>

        <FieldRow style={{ marginTop: 30 }}>
          <FieldLabel></FieldLabel>
          <div>
            <div className=""></div>
            <FieldItem
              onClick={(): void =>
                setFormData(
                  (v): WorkshopOrderData => ({
                    ...v,
                    privacyPolicy: !v.privacyPolicy,
                  })
                )
              }
            >
              <Checkbox error={formErrors.privacyPolicy}>
                <SVGImage
                  className={formData.privacyPolicy ? 'visible check' : 'check'}
                  svgProps={[KlosySVGId.checked, 'checked', 15, 15]}
                />
                <div className="empty-check"></div>{' '}
                <div>
                  {t('workshopPage.orderModal.privacy1')}{' '}
                  <Link href="/privacy-policy-workshop" target="_blank">
                    Regulaminem świadczenia usług
                  </Link>
                  {' oraz '}
                  <Link href="/privacy-policy" target="_blank">
                    Polityką Prywatności
                  </Link>{' '}
                  {t('workshopPage.orderModal.privacy2')}
                </div>
              </Checkbox>
            </FieldItem>
          </div>
        </FieldRow>

        <FieldRow>
          <FieldLabel></FieldLabel>
          <div>
            <FieldItem
              onClick={(): void =>
                setFormData(
                  (v): WorkshopOrderData => ({
                    ...v,
                    rodo: !v.rodo,
                  })
                )
              }
            >
              <Checkbox error={formErrors.rodo}>
                <SVGImage
                  className={formData.rodo ? 'visible check' : 'check'}
                  svgProps={[KlosySVGId.checked, 'checked', 15, 15]}
                />
                <div className="empty-check"></div>{' '}
                <div>{t('workshopPage.orderModal.rodo')} </div>
              </Checkbox>
            </FieldItem>
          </div>
        </FieldRow>

        <FieldRow style={{ textAlign: 'right', marginTop: 35 }}>
          <FieldLabel color={palette.darkBlue} fs="14px">
            <div>
              {t('workshopPage.orderModal.price')}
              <br />
              {t('workshopPage.orderModal.price2')}
            </div>
          </FieldLabel>

          <FieldItem bold color={palette.darkBlue} fs="25px">
            <div>
              {_.sum(
                workshops.length === 1
                  ? workshops[0].price[lngIndex]
                  : formData.bookings.map(
                      ({ workshop }) => +workshop.price[lngIndex]
                    )
              )}
              ,00 {t('currency')} <br />
            </div>
          </FieldItem>
        </FieldRow>

        <FieldRow style={{ textAlign: 'right', marginTop: -20 }}>
          <div></div>

          <FieldLabel
            color={palette.darkBlue}
            style={{ textAlign: 'left' }}
            fs="13px"
          >
            {t('workshopPage.orderModal.reservation')}{' '}
            {`(${
              workshops.length === 1
                ? +workshops[0].prepayment[lngIndex]
                : _.sum(
                    formData.bookings.map(
                      ({ workshop }): number => +workshop.prepayment[lngIndex]
                    )
                  )
            },-)`}
          </FieldLabel>
        </FieldRow>

        <DarkButton
          onClick={(): Promise<void> => onSendOrder()}
          style={{ maxWidth: '300px', marginTop: 30 }}
        >
          {sendOrderPending ? (
            <Spin indicator={antIcon} />
          ) : (
            t('workshopPage.orderModal.send')
          )}
        </DarkButton>
      </>
    )

    return (
      <StyledModal {...modalProps} wrapClassName="workshop-modal">
        {orderSentMessage ? sentMessage : form}

        <div className="footer">
          <Trans i18nKey="order-modal.footer">
            {[
              <StyledAnchorLink
                href="#faq"
                key="1"
                onClick={(): void => onCloseModal()}
              ></StyledAnchorLink>,
              <Link key="2" href="mailto:klosy.info@gmail.com"></Link>,
              <Link key="3" href="tel:48600113805"></Link>,
            ]}
          </Trans>
        </div>
      </StyledModal>
    )
  })()
}

export default OrderModal
