import { Carousel } from 'antd'
import { useStaticQuery, graphql } from 'gatsby'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { mediaQueries } from '~components/homepage/styled/constants'
import { ResponsiveImage } from '~components/homepage/styled/shared'
import { findImages } from '~components/homepage/utils'
import { ImagesQueryData } from '~src/types/graphql-types'
import { NextArrow, PrevArrow } from './styled'

const StyledCarousel = styled(Carousel)`
  width: 700px;

  @media (max-width: ${mediaQueries.small}) {
    & {
      width: 80%;
    }
  }
`

export const WorkshopCarousel = ({ images }: { images: string[] }) => {
  const [screenWidth, setScreenWidth] = useState<number>()
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const query = useStaticQuery<ImagesQueryData>(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxHeight: 700) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  const sliderSettings = {
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }

  const imagesSlider = findImages(query, images)

  const renderSlides = (height: string): object[] =>
    imagesSlider.map(
      ({ node }): ReactElement => (
        <ResponsiveImage
          key={node.name}
          src={node.childImageSharp.fluid.src}
          height={height}
          width="100%"
          center
        />
      )
    )

  const height = screenWidth <= 1024 ? 320 : 500

  return (
    <StyledCarousel
      dots={false}
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      arrows
      {...sliderSettings}
      style={{
        height,
        margin: '0 auto',
      }}
    >
      {renderSlides(`${height}px`)}
    </StyledCarousel>
  )
}
